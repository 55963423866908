import React from 'react';

import Page from '~layout/Page';

import LoginContainer from '~containers/checkout/Login';

const Login = () => (
  <Page redirectToMembers>
    <LoginContainer />
  </Page>
);

export default Login;
